<!-- eslint-disable -->
<script setup lang="ts">
import { ref, onMounted } from 'vue';
import useFonts from '../composables/useFonts';
const { setFont } = useFonts();

onMounted(() => {
    setFont()
})

</script>

<template lang="pug">
.app-wrapper 
    GlobalAppModalError
    GlobalAppSpinner
    slot
    
</template>



<style lang="scss"></style>